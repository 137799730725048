import PropTypes from 'prop-types';

import {
    Group,
    Stack,
    Text,
} from '@mantine/core';
import {
    Price,
    PriceDimension,
} from '../types';
import {PropertiesCard} from "../../../components/PropertiesCard.tsx";

const DimensionsTable = ({dimensions}: { dimensions: PriceDimension[] }) => {
    return (
        <>
            {dimensions.map((dimension, index) => (<Stack key={index} gap={'xs'}>
                <Group ml={'1em'} gap={'xs'}><Text
                    fw={600}>Name:</Text><Text>{dimension.name}</Text></Group>
                <Group ml={'1em'} gap={'xs'}><Text
                    fw={600}>Value:</Text><Text>{dimension.value}</Text></Group>
            </Stack>))}
        </>
    );
};

DimensionsTable.propTypes = {
    dimensions: PropTypes.array,
};

export const Profile = ({record}: { record: Price }) => {
    console.debug(record)

    const properties = [
        {
            label: 'ID',
            value: record.id as string,
            span: 6,
        }, {
            label: 'Product',
            value: record.product as string,
            span: 6,
        }, {
            label: 'Unit Amount',
            value: record.unit_amount as string,
            span: 6,
        }, {
            label: 'Currency',
            value: record.currency as string,
            span: 6,
        }, {
            label: 'Nickname',
            value: record.nickname as string,
            span: 6,
        }, {
            label: 'Billing Scheme',
            value: record.billing_scheme as string,
            span: 6,
        }, {
            label: 'Recurring Interval',
            value: record.recurring?.interval as string,
            span: 6,
        }, {
            label: 'Recurring Interval Count',
            value: record.recurring?.interval_count,
            span: 6,
        }, {
            label: 'Created By',
            value: typeof record.createdBy !== 'undefined' && record.createdBy !== null ? [record.createdBy.givenName, record.createdBy.familyName].join(' ') : 'n/a',
            rbac: 'UpdatePrice',
            span: 3,
        },
        {
            label: 'Created',
            value: record.created as string,
            formatAs: 'Timestamp',
            span: 3,
        }, {
            label: 'Managed By',
            value: typeof record.managedBy !== 'undefined' && record.managedBy !== null ? [record.managedBy.givenName, record.managedBy.familyName].join(' ') : 'n/a',
            rbac: 'UpdatePrice',
            span: 3,
        },
        {
            label: 'Last Updated',
            value: record.updated as string,
            formatAs: 'Timestamp',
            span: 3,
        }];


    return (
        <PropertiesCard properties={properties}/>
    );


};

Profile.propTypes = {
    record: PropTypes.shape({
                                name: PropTypes.string,
                                description: PropTypes.string,
                                status: PropTypes.string,
                                createdBy: PropTypes.shape({
                                                               givenName: PropTypes.string,
                                                               familyName: PropTypes.string,
                                                           }),
                                managedBy: PropTypes.shape({
                                                               givenName: PropTypes.string,
                                                               familyName: PropTypes.string,
                                                           }),
                                created: PropTypes.string,
                                updated: PropTypes.string,
                            }),
};


