import {stage} from '../stage';

const cognito = {
    prod: {
        domain: 'flexinet-main-user-pool-prod.auth.us-west-2.amazoncognito.com',
        redirectSignIn: 'https://marketplace.flexi.network',
        redirectSignOut: 'https://portal.flexi.network',
        region: 'us-west-2',
        userPoolId: 'us-west-2_JCF3ZzEG7',
        userPoolWebClientId: '4ddg74c9jma0nnbbr61hm5refi',
        identityPoolId: 'us-west-2:d6db8349-183a-43fe-8441-384ccaa7de45',
    },
    test: {
        domain: 'flexinet-main-user-pool-test.auth.us-west-2.amazoncognito.com',
        redirectSignIn: 'https://marketplace.test.flexi.network',
        redirectSignOut: 'https://portal.test.flexi.network',
        region: 'us-west-2',
        userPoolId: 'us-west-2_8yE2sTkD5',
        userPoolWebClientId: '1q5ahpc43c7mh59ktba9tv4363',
        identityPoolId: 'us-west-2:08002670-6561-48aa-a68e-7566cf02b237',
    },
    dev: {
        domain: 'flexinet-main-user-pool-dev.auth.us-west-2.amazoncognito.com',
        redirectSignIn: 'https://marketplace.dev.flexi.network',
        redirectSignOut: 'https://portal.dev.flexi.network',
        region: 'us-west-2',
        userPoolId: 'us-west-2_jE2FtiV4E',
        userPoolWebClientId: 'picu88p8vjg4otj8a0n27v6ch',
        identityPoolId: 'us-west-2:97ea1fc0-0602-4d99-99fe-14c956a7de7d',
    },
    ide: {
        domain: 'flexinet-main-user-pool-dev.auth.us-west-2.amazoncognito.com',
        redirectSignIn: 'https://localhost:8093',
        redirectSignOut: 'https://localhost:8081',
        region: 'us-west-2',
        userPoolId: 'us-west-2_jE2FtiV4E',
        userPoolWebClientId: 'picu88p8vjg4otj8a0n27v6ch',
        identityPoolId: 'us-west-2:e16a8430-d13a-4212-9992-1368b4ec2c20',
    },
};

const oauth = {
    // Domain name
    domain: cognito[stage].domain,

    // Authorized scopes
    scope: [
        'phone',
        'email',
        'profile',
        'openid',
        'aws.cognito.signin.user.admin',
    ],

    // Callback URL
    redirectSignIn: cognito[stage].redirectSignIn,

    // Sign out URL
    redirectSignOut: cognito[stage].redirectSignOut,

    // 'code' for Authorization code grant,
    // 'token' for Implicit grant
    responseType: 'token',

    // optional, for Cognito hosted ui specified options
    options: {
        // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
        AdvancedSecurityDataCollectionFlag: true,
    },
};

//OPTIONAL - Configuration for cookie storage
const cookieStorage = {
    // REQUIRED - Cookie domain (only required if cookieStorage is provided)
    domain: cognito[stage].domain,

    // OPTIONAL - Cookie path
    path: '/',

    // OPTIONAL - Cookie expiration in days
    expires: 365,

    // OPTIONAL - Cookie secure flag
    secure: true,
};

const useCookieStorage = false;

export const config = {
    // REQUIRED - Amazon Cognito Identity Pool ID
    identityPoolId: cognito[stage].identityPoolId,

    // REQUIRED - Amazon Cognito Region
    region: cognito[stage].region,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: cognito[stage].userPoolId,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: cognito[stage].userPoolWebClientId,

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,
    cookieStorage: useCookieStorage ? cookieStorage : null,
    oauth: oauth,
};
