import * as Yup from 'yup';

export const RecordSchema = Yup.object()
                               .shape({
                                          currency: Yup.string()
                                                       .required('Required'),
                                          product: Yup.string()
                                                      .required('Required'),
                                          unit_amount: Yup.string()
                                                          .required('Required'),
                                      });
