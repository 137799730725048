import {
    getCard,
    listCards
} from './queries';
import {
    createCard,
    deleteCard,
    updateCard,
} from './mutations';

import {GraphQLQuery,} from '@aws-amplify/api';

import {API} from 'aws-amplify';
import {Card} from "../types.ts";


export type ListCards = {
    listCards: { items: Card[] }
}

export type GetCard = {
    getCard: Card
}

export type CreateCard = {
    createCard: Card
}

export type UpdateCard = {
    updateCard: Card
}

export type DeleteCard = {
    deleteCard: string
}


export const fetchRecords = async (customerId: string) => {

    try {
        const response = await API.graphql<GraphQLQuery<ListCards>>({
                                                                        query: listCards,
                                                                        variables: {
                                                                            customer: customerId
                                                                        },
                                                                    });

        return response.data?.listCards.items;
    } catch (e) {
        console.trace(e);
    }
};

export const fetch = async (customerId: string, id: string) => {
    try {
        const response = await API.graphql<GraphQLQuery<GetCard>>({
                                                                      query: getCard,
                                                                      variables: {
                                                                          customer: customerId,
                                                                          id: id
                                                                      },
                                                                  });
        return response.data?.getCard;
    } catch (e) {
        console.trace(e);
    }
};

export const add = async (payload: object) => {

    try {
        const response = await API.graphql<GraphQLQuery<CreateCard>>({
                                                                         query: createCard,
                                                                         variables: {input: payload},
                                                                     });
        console.debug('in the service response', response);

        return response.data?.createCard;
    } catch (e) {
        console.trace(e);
    }
};

export const update = async (record: Card) => {

    delete record.created;
    delete record.updated;

    try {
        const response = await API.graphql<GraphQLQuery<UpdateCard>>({
                                                                         query: (updateCard),
                                                                         variables: {input: record},
                                                                     });

        return response.data?.updateCard;
    } catch (e) {
        console.trace(e);
    }
};

export const deleteRecord = async (customerId: string, id: string) => {
    try {
        const response = await API.graphql<GraphQLQuery<DeleteCard>>({
                                                                         query: (deleteCard),
                                                                         variables: {
                                                                             input: {
                                                                                 customer: customerId,
                                                                                 id: id
                                                                             }
                                                                         },

                                                                     });
        return response.data?.deleteCard;
    } catch (e) {
        console.trace(e);
    }
};
