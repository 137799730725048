import {NavLink as ReactRouteNavLink} from 'react-router-dom';
import {useEffect} from "react";
import {usePrices} from "../../../prices";
import {
    DashboardWidgetBuilder,
    WidgetDataPoint,
    WidgetType
} from "@flexinet/ui-components";

export const PricesWidget = () => {

    const {
        items,
        fetchRecords
    } = usePrices()

    useEffect(() => {
        fetchRecords()
    }, []);

    const dataPoints: WidgetDataPoint[] = [];
    dataPoints.push({
                        value: items.length,
                        label: 'Prices',
                        color: 'flexinet',
                    });


    const widget: WidgetType = {
        header: {
            title: 'Prices',
        },
        body: {
            dataPoints: dataPoints,
            metrics: [],
        },
        footer: {
            actionItems: [
                {
                    label: 'View All',
                    component: {ReactRouteNavLink},
                    to: '/prices',
                    visible: true
                },
                {
                    label: 'Create Price',
                    component: {ReactRouteNavLink},
                    to: '/prices/add',
                    visible: true,
                },
            ],
        }
    };

    return <DashboardWidgetBuilder widget={widget}/>;
};
