import {RecordForm} from '../forms/RecordForm';


import {useSubscription} from '../hooks/useSubscription';
import {IndexPageNavigation} from "../components/IndexPageNavigation";
import {FormPageLayout} from "@flexinet/ui-components";


export const AddPage = () => {

    const {
        record,
        createRecord,
    } = useSubscription();

    return (
        <FormPageLayout
            title='Add Subscription'
            pageNavigation={<IndexPageNavigation/>}
        >
            <RecordForm record={record} handleSubmit={createRecord}/>
        </FormPageLayout>
    );
};
