import {
    useEffect,
    useState,
} from 'react';

import {Autocomplete,} from '@mantine/core';

import {usePrices} from '../hooks/usePrices.ts';
import {Price} from '../types';
import {FieldProps} from 'formik';


export const PricesAutocomplete = ({
                                       field: {
                                           name,
                                           value: fieldValue,
                                       },
                                       form: {setFieldValue},
                                       ...rest
                                   }: FieldProps) => {

    const {
        items: records,
        fetchRecords
    } = usePrices();

    const [value, setValue] = useState('');
    const [disabled, setDisabled] = useState(false);


    useEffect(() => {
        fetchRecords();
    }, []);

    const data = records.map((record: Price) => {
        return {
            ...record,
            label: record.id,
            value: record.id,
        };
    });

    useEffect(() => {
        if (records.length === 0) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }

        const found = records.find((item: Price) => item.id === fieldValue);
        if (typeof found !== 'undefined' && Object.prototype.hasOwnProperty.call(found, 'id')) {
            setValue(found.id);
        }
    }, [records.length]);

    const handleChange = (value: string) => {
        if (!Array.isArray(data)) {
            throw new Error('Data is expected to be an array');
        }
        const {id} = data.find((item) => item.id === value) || {};

        if (typeof id !== 'undefined') {
            setFieldValue(name, id);
        }

        setValue(value);
    };

    return (
        <Autocomplete
            name={name}
            value={value}
            onChange={handleChange}
            data={data}
            disabled={disabled}
            placeholder='Select Component'
            {...rest}
        />
    );
};

