import {stage} from './stage';

const kinesis = {
    prod: {
        streamName:
            '',
        region: 'us-west-2',
    },
    test: {
        streamName:
            '',
        region: 'us-west-2',
    },
    dev: {
        streamName:
            '',
        region: 'us-west-2',
    },
    ide: {
        streamName:
            '',
        region: 'us-west-2',
    },
};

export default kinesis[stage];
