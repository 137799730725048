import {useEffect} from 'react';

import {Auth} from 'aws-amplify';

import {Center} from '@mantine/core';
import {PlatformLoader} from '@flexinet/ui-components';

export const SignOutPage = () => {
    useEffect(() => {
        const signOut = async () => {
            await Auth.signOut()
        };

        signOut();
    }, []);

    return (
        <Center>
            <PlatformLoader/>
        </Center>
    );
};
