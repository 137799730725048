import PropTypes from 'prop-types';
import {Field} from 'formik';

import {SimpleGrid} from '@mantine/core';
import {CustomersAutocomplete} from "../../customers/inputs/CustomersAutocomplete.tsx";


export const FormComponent = () => {

    return (
        <SimpleGrid cols={1}>

            <Field
                name='customer'
                label='Customer'
                description='Please enter the customer'
                component={CustomersAutocomplete}
                withAsterisk
            />

        </SimpleGrid>
    );
};

FormComponent.propTypes = {
    values: PropTypes.object.isRequired,
};
