import {
    createAsyncThunk,
    createSlice,
} from '@reduxjs/toolkit';

import {
    add,
    deleteRecord,
    fetch,
    fetchRecords,
    update,
} from '../services/BankAccountsService.ts';


import {
    deleteOneCase,
    fetchManyCase,
    fetchOneCase,
    saveOneCase,
    updateOneCase,
} from '../../../store/RecordsSliceCaseHelpers';
import {BankAccount} from '../types';

const entityKey = 'bankAccounts';

interface BankAccountsState {
    byId: { [key: string]: BankAccount },
    ids: string[],
    totalCount: number,
    loading: boolean,
    error: string
}

const initialState = {
    byId: {},
    ids: [],
    totalCount: 0,
    loading: false,
    error: '',
} as BankAccountsState;


const normalizeRecord = (record: BankAccount) => {
    return Object.assign({}, record);
};

// generates pending, fulfilled and rejected
export const fetchBankAccounts = createAsyncThunk(
    `${entityKey}/fetchRecords`,
    (customerId: string) => {

        return fetchRecords(customerId)
            .then((response) => {
                if (response) {
                    return response.map((record: BankAccount) => {
                        return normalizeRecord(record);
                    });
                }
            });
    },
);

export const fetchBankAccount = createAsyncThunk(
    `${entityKey}/fetch`,
    ({
         customerId,
         id
     }: {
        customerId: string,
        id: string
    }) => {
        return fetch(customerId, id)
            .then((response) => {
                if (typeof response !== 'undefined') {
                    return normalizeRecord(response);
                }
            });
    });

export const saveBankAccount = createAsyncThunk(
    `${entityKey}/add`,
    ({
         customerId,
         payload
     }: { customerId: string, payload: BankAccount }) => {

        const modified = Object.assign({}, payload, {customer: customerId})

        return add(modified)
            .then((response) => {
                if (response) {
                    return normalizeRecord(response);
                }
            });
    });

export const updateBankAccount = createAsyncThunk(
    `${entityKey}/update`,
    ({
         customerId,
         id,
         record,
     }: { customerId: string, id: string, record: BankAccount }) => {

        const modified = Object.assign({}, record, {
            id: id,
            customer: customerId
        })

        return update(modified)
            .then((response) => {
                if (response) {
                    return normalizeRecord(response);
                }
            });
    },
);

export const deleteBankAccount = createAsyncThunk(
    `${entityKey}/deleteRecord`,
    ({
         customerId,
         id
     }: {
        customerId: string,
        id: string
    }) => {
        return deleteRecord(customerId, id)
            .then(() => id);
    },
);


export const storeBankAccount = createAsyncThunk(
    `${entityKey}/store`,
    (payload: BankAccount) => {
        return normalizeRecord(payload);
    },
);


const componentsSlice = createSlice({
                                        name: entityKey,
                                        initialState,
                                        reducers: {
                                            clearState: () => initialState,
                                        },
                                        extraReducers: (builder) => {
                                            // FETCH MANY
                                            fetchManyCase(builder, fetchBankAccounts, entityKey);

                                            // FETCH ONE
                                            fetchOneCase(builder, fetchBankAccount);

                                            // SAVE ONE
                                            saveOneCase(builder, saveBankAccount);

                                            // UPDATE ONE
                                            updateOneCase(builder, updateBankAccount);

                                            updateOneCase(builder, storeBankAccount);

                                            // DELETE ONE
                                            deleteOneCase(builder, deleteBankAccount);
                                        },
                                    });

export default componentsSlice.reducer;
