import PropTypes from 'prop-types';
import {Field} from 'formik';

import {Grid} from '@mantine/core';
import {FormikStripeAddress} from "../../../components/FormikStripeAddress.tsx";
import {FormikStripeCard} from "../../../components/FormikStripeCard.tsx";


export const FormComponent = () => {

    return (
        <Grid>
            <Grid.Col span={12}>
                <Field
                    name='card'
                    label='Card'
                    description='Please enter the card information'
                    component={FormikStripeCard}
                    withAsterisk
                />
            </Grid.Col>
            <Grid.Col span={12}>
                <Field
                    name='address'
                    label='Address'
                    description='Please enter the card address'
                    component={FormikStripeAddress}
                    options={{mode: 'billing'}}
                    withAsterisk
                />
            </Grid.Col>
        </Grid>
    );
};

FormComponent.propTypes = {
    values: PropTypes.object.isRequired,
};
