import {
    deleteCard,
    fetchCard,
    saveCard,
    updateCard,
} from '../reducers/recordsSlice';
import {recordSelector} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from '../../../hooks/useTypedReactRedux';
import {Card,} from '../types';

export const useCard = (customerId: string, cardId?: string) => {

    if (typeof customerId === 'undefined') {
        throw new Error('customerId is required parameter')
    }

    const dispatch = useAppDispatch();

    const {
        record,
        loading,
    } = useAppSelector((state) =>
                           recordSelector(state, cardId ? cardId : ''),
    );

    // FETCH
    const fetchRecord = () => {
        if (typeof cardId === 'undefined') {
            throw new Error('cardId is required parameter');
        }
        dispatch(fetchCard({
                               customerId: customerId,
                               id: cardId
                           }));
    };

    // CREATE, UPDATE DELETE
    const createRecord = (values: Card) => {
        dispatch(saveCard({
                              customerId: customerId,
                              payload: values
                          }));
    };

    const updateRecord = (values: Card) => {
        if (typeof cardId === 'undefined') {
            throw new Error('cardId is required parameter');
        }

        dispatch(updateCard({
                                customerId: customerId,
                                id: cardId,
                                record: values,
                            }));
    };

    const deleteRecord = () => {
        if (typeof cardId === 'undefined') {
            throw new Error('cardId is required parameter');
        }
        dispatch(deleteCard({
                                customerId: customerId,
                                id: cardId
                            }));
    };

    return {
        record,
        loading,
        fetchRecord,
        createRecord,
        updateRecord,
        deleteRecord,
    };
};
