import {
    deleteCustomer,
    fetchCustomer,
    saveCustomer,
    updateCustomer,
} from '../reducers/recordsSlice';
import {recordSelector} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from '../../../hooks/useTypedReactRedux';
import {Customer} from '../types';

export const useCustomer = (customerId?: string) => {


    const dispatch = useAppDispatch();

    const {
        record,
        loading,
    } = useAppSelector((state) =>
                           recordSelector(state, customerId ? customerId : ''),
    );

    // FETCH
    const fetchRecord = () => {
        if (typeof customerId === 'undefined') {
            throw new Error('customerId is required parameter');
        }
        dispatch(fetchCustomer(customerId));
    };

    // CREATE, UPDATE DELETE
    const createRecord = (values: Customer) => {
        dispatch(saveCustomer(values));
    };

    const updateRecord = (values: Customer) => {
        if (typeof customerId === 'undefined') {
            throw new Error('customerId is required parameter');
        }

        dispatch(updateCustomer({
                                    id: customerId,
                                    record: values,
                                }));
    };

    const deleteRecord = () => {
        if (typeof customerId === 'undefined') {
            throw new Error('customerId is required parameter');
        }
        dispatch(deleteCustomer(customerId));
    };

    return {
        record,
        loading,
        fetchRecord,
        createRecord,
        updateRecord,
        deleteRecord,
    };
};
