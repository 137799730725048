import {
    Navigate,
    Route,
    Routes,
} from 'react-router-dom';

import {IndexPage} from './pages/IndexPage';
import {AddPage} from './pages/AddPage';
import {EditPage} from "./pages/EditPage.tsx";

import {useAccessControl} from '../../hooks/useAccessControl';
import {useUserAttributes} from "../../hooks/useUserAttributes.ts";
import {PlatformLoader} from "@flexinet/ui-components";

import {AddPage as AddBankAccountPage} from '../bank-accounts/pages/AddPage';
import {EditPage as EditBankAccountPage} from '../bank-accounts/pages/EditPage.tsx';
import {AddPage as AddCardPage} from '../cards/pages/AddPage';
import {EditPage as EditCardPage} from '../cards/pages/EditPage.tsx';


export const CustomersLayout = () => {

    const {role} = useUserAttributes();

    const {evaluateAction} = useAccessControl(role)

    if (role === '') {
        return <PlatformLoader message={'Loading role...Please wait!'}/>
    }


    return (
        <Routes>
            <Route
                path='/'
                element={
                    evaluateAction('ListCustomers') ? (
                        <IndexPage/>
                    ) : (
                        <Navigate to='/'/>
                    )
                }
            />
            <Route
                path='add'
                element={
                    evaluateAction('CreateCustomer') ? (
                        <AddPage/>
                    ) : (
                        <Navigate to='/customers'/>
                    )
                }
            />
            <Route
                path=':id'
                element={
                    evaluateAction('GetCustomer') ? (
                        <EditPage/>
                    ) : (
                        <Navigate to='/customers'/>
                    )
                }

            >
            </Route>


            <Route
                path=':id/bank-accounts/add'
                element={
                    evaluateAction('CreateBankAccount') ? (
                        <AddBankAccountPage/>
                    ) : (
                        <Navigate to='/customers/:id'/>
                    )
                }
            />

            <Route
                path=':id/bank-accounts/:bankAccountId'
                element={
                    evaluateAction('GetBankAccount') ? (
                        <EditBankAccountPage/>
                    ) : (
                        <Navigate to='/customers/:id'/>
                    )
                }
            />

            <Route
                path=':id/cards/add'
                element={
                    evaluateAction('CreateCard') ? (
                        <AddCardPage/>
                    ) : (
                        <Navigate to='/customers/:id'/>
                    )
                }
            />

            <Route
                path=':id/cards/:cardId'
                element={
                    evaluateAction('GetCard') ? (
                        <EditCardPage/>
                    ) : (
                        <Navigate to='/customers/:id'/>
                    )
                }
            />

        </Routes>
    );
};
