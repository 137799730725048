import {
    getBankAccount,
    listBankAccounts
} from './queries';
import {
    createBankAccount,
    deleteBankAccount,
    updateBankAccount,
} from './mutations';

import {GraphQLQuery,} from '@aws-amplify/api';

import {API} from 'aws-amplify';
import {BankAccount} from "../types.ts";

export type ListBankAccounts = {
    listBankAccounts: { items: BankAccount[] }
}

export type GetBankAccount = {
    getBankAccount: BankAccount
}

export type CreateBankAccount = {
    createBankAccount: BankAccount
}

export type UpdateBankAccount = {
    updateBankAccount: BankAccount
}

export type DeleteBankAccount = {
    deleteBankAccount: string
}


export const fetchRecords = async (customerId: string) => {

    try {
        const response = await API.graphql<GraphQLQuery<ListBankAccounts>>({
                                                                               query: listBankAccounts,
                                                                               variables: {customer: customerId},
                                                                           });
        return response.data?.listBankAccounts.items;
    } catch (e) {
        console.trace(e);
    }
};

export const fetch = async (customerId: string, id: string) => {
    try {
        const response = await API.graphql<GraphQLQuery<GetBankAccount>>({
                                                                             query: getBankAccount,
                                                                             variables: {
                                                                                 customer: customerId,
                                                                                 id: id
                                                                             },
                                                                         });
        return response.data?.getBankAccount;
    } catch (e) {
        console.trace(e);
    }
};

export const add = async (payload: object) => {

    try {
        const response = await API.graphql<GraphQLQuery<CreateBankAccount>>({
                                                                                query: createBankAccount,
                                                                                variables: {input: payload},
                                                                            });
        console.debug('in the service response', response);

        return response.data?.createBankAccount;
    } catch (e) {
        console.trace(e);
    }
};

export const update = async (record: BankAccount) => {

    delete record.created;
    delete record.updated;

    try {
        const response = await API.graphql<GraphQLQuery<UpdateBankAccount>>({
                                                                                query: (updateBankAccount),
                                                                                variables: {input: record},
                                                                            });

        return response.data?.updateBankAccount;
    } catch (e) {
        console.trace(e);
    }
};

export const deleteRecord = async (customerId: string, id: string) => {
    try {
        const response = await API.graphql<GraphQLQuery<DeleteBankAccount>>({
                                                                                query: (deleteBankAccount),
                                                                                variables: {
                                                                                    input: {
                                                                                        customer: customerId,
                                                                                        id: id
                                                                                    }
                                                                                },

                                                                            });
        return response.data?.deleteBankAccount;
    } catch (e) {
        console.trace(e);
    }
};
