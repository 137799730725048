import PropTypes from 'prop-types';
import {Formik} from 'formik';

import {FormComponent} from './FormComponent';

import {RecordSchema} from './RecordSchema';

import {CustomerInvoiceItem} from '../types';
import {FormWithButtons} from "@flexinet/ui-components";
import {stage} from "../../../config/stage.ts";
import {useModals} from "@mantine/modals";

export const RecordForm = ({
                               record,
                               handleSubmit,
                           }: {
    record: Omit<CustomerInvoiceItem, 'id'>,
    handleSubmit: (values: CustomerInvoiceItem) => void
}) => {

    const {closeAll} = useModals()

    const onSubmit = async (values: CustomerInvoiceItem) => {
        handleSubmit(values);
        closeAll()

    };

    const onCancel = () => {
        closeAll()
    };


    let initialValues = {
        currency: 'usd',
    } as CustomerInvoiceItem;

    if (record) {
        initialValues = Object.assign({}, initialValues, record);
    }


    return (
        <Formik
            initialValues={initialValues}
            validationSchema={RecordSchema}
            onSubmit={onSubmit}
        >
            {({
                  handleSubmit,
                  isSubmitting,
                  ...rest
              }) => (
                <FormWithButtons handleSubmit={handleSubmit} isSubmitting={isSubmitting} handleCancel={onCancel}
                                 debug={false && stage === 'ide'} {...rest}  >
                    <FormComponent {...rest} />
                </FormWithButtons>
            )}


        </Formik>
    );
};

RecordForm.propTypes = {
    record: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
};
