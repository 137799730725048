import {NavLink as ReactRouteNavLink} from 'react-router-dom';
import {useEffect} from "react";
import {useProducts} from "../../../products";
import {
    DashboardWidgetBuilder,
    WidgetDataPoint,
    WidgetType
} from "@flexinet/ui-components";

export const ProductsWidget = () => {

    const {
        items,
        fetchRecords
    } = useProducts()

    useEffect(() => {
        fetchRecords()
    }, []);

    const dataPoints: WidgetDataPoint[] = [];
    dataPoints.push({
                        value: items.length,
                        label: 'Products',
                        color: 'flexinet',
                    });


    const widget: WidgetType = {
        header: {
            title: 'Products',
        },
        body: {
            dataPoints: dataPoints,
            metrics: [],
        },
        footer: {
            actionItems: [
                {
                    label: 'View All',
                    component: {ReactRouteNavLink},
                    to: '/products',
                    visible: true
                },
                {
                    label: 'Create Product',
                    component: {ReactRouteNavLink},
                    to: '/products/add',
                    visible: true,
                },
            ],
        }
    };

    return <DashboardWidgetBuilder widget={widget}/>;
};
