import PropTypes from 'prop-types';
import {Formik} from 'formik';

import {FormComponent} from './FormComponent';

import {RecordSchema} from './RecordSchema';

import {InvoiceItem} from '../types';
import {FormWithButtons} from "@flexinet/ui-components";
import {stage} from "../../../config/stage.ts";
import {
    useElements,
    useStripe
} from "@stripe/react-stripe-js";
import {useModals} from "@mantine/modals";

export const RecordForm = ({
                               record,
                               handleSubmit,
                           }: {
    record: Omit<InvoiceItem, 'id'>,
    handleSubmit: (values: InvoiceItem) => void
}) => {

    const {closeAll} = useModals()
    const stripe = useStripe();
    const elements = useElements();


    const onSubmit = async (values: InvoiceItem) => {


        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        // const ibanElement = elements.getElement('iban')
        // if (!ibanElement) {
        //     throw new Error('iban element is require parameter')
        // }
        //
        // const result = await stripe.createToken(ibanElement, {
        //     currency: values.currency,
        // })
        //
        // console.debug(result)
        //
        // if (typeof result.token === 'undefined') {
        //     throw new Error('token is required parameter')
        // }
        //
        // if (typeof result.token.bank_account === 'undefined') {
        //     throw new Error('token bank_account is required parameter')
        // }
        //
        // const payload = {
        //     source: result.token.bank_account.id
        // }
        // handleSubmit(payload);

        console.debug({values})
        handleSubmit(values);
        closeAll()

    };

    const onCancel = () => {
        closeAll()
    };


    let initialValues = {
        currency: 'usd',
    } as InvoiceItem;

    if (record) {
        initialValues = Object.assign({}, initialValues, record);
    }


    return (
        <Formik
            initialValues={initialValues}
            validationSchema={RecordSchema}
            onSubmit={onSubmit}
        >
            {({
                  handleSubmit,
                  isSubmitting,
                  ...rest
              }) => (
                <FormWithButtons handleSubmit={handleSubmit} isSubmitting={isSubmitting} handleCancel={onCancel}
                                 debug={true && stage === 'ide'} {...rest}  >
                    <FormComponent {...rest} />
                </FormWithButtons>
            )}


        </Formik>
    );
};

RecordForm.propTypes = {
    record: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
};
