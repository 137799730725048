export const createCard = `
    mutation createCard($input: CreateCardInput!) {
        createCard(input:$input) {
            id
            customer
            number
            exp_month
            exp_year
            name
    }
}`;

export const updateCard = `
    mutation updateCard($input: UpdateCardInput!) {
        updateCard(input:$input){
            id
            customer
            number
            exp_month
            exp_year
            name
        }
}`;

export const deleteCard = `
    mutation deleteCard($input: DeleteCardInput!) {
        deleteCard(input:$input) 
}`;
