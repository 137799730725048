import {
    createAsyncThunk,
    createSlice,
} from '@reduxjs/toolkit';

import {
    add,
    deleteRecord,
    fetch,
    fetchRecords,
    update,
} from '../services/CustomersService.ts';


import {
    deleteOneCase,
    fetchManyCase,
    fetchOneCase,
    saveOneCase,
    updateOneCase,
} from '../../../store/RecordsSliceCaseHelpers';
import {Customer} from '../types';

const entityKey = 'customers';

interface CustomersState {
    byId: { [key: string]: Customer },
    ids: string[],
    totalCount: number,
    loading: boolean,
    error: string
}

const initialState = {
    byId: {},
    ids: [],
    totalCount: 0,
    loading: false,
    error: '',
} as CustomersState;


const normalizeRecord = (record: Customer) => {
    return Object.assign({}, record);
};

// generates pending, fulfilled and rejected
export const fetchCustomers = createAsyncThunk(
    `${entityKey}/fetchRecords`,
    () => {

        return fetchRecords({})
            .then((response) => {
                if (response) {
                    return response.map((record: Customer) => {
                        return normalizeRecord(record);
                    });
                }
            });
    },
);

export const fetchCustomer = createAsyncThunk(
    `${entityKey}/fetch`,
    (id: string) => {
        return fetch(id)
            .then((response) => {
                if (typeof response !== 'undefined') {
                    return normalizeRecord(response);
                }
            });
    });

export const saveCustomer = createAsyncThunk(
    `${entityKey}/add`,
    (payload: Customer) => {
        return add(payload)
            .then((response) => {
                if (response) {
                    return normalizeRecord(response);
                }
            });
    });

export const updateCustomer = createAsyncThunk(
    `${entityKey}/update`,
    ({
         id,
         record,
     }: { id: string, record: Customer }) => {

        return update(id, record)
            .then((response) => {
                if (response) {
                    return normalizeRecord(response);
                }
            });
    },
);

export const deleteCustomer = createAsyncThunk(
    `${entityKey}/deleteRecord`,
    (id: string) => {
        return deleteRecord(id)
            .then(() => id);
    },
);


export const storeCustomer = createAsyncThunk(
    `${entityKey}/store`,
    (payload: Customer) => {
        return normalizeRecord(payload);
    },
);


const componentsSlice = createSlice({
                                        name: entityKey,
                                        initialState,
                                        reducers: {
                                            clearState: () => initialState,
                                        },
                                        extraReducers: (builder) => {
                                            // FETCH MANY
                                            fetchManyCase(builder, fetchCustomers, entityKey);

                                            // FETCH ONE
                                            fetchOneCase(builder, fetchCustomer);

                                            // SAVE ONE
                                            saveOneCase(builder, saveCustomer);

                                            // UPDATE ONE
                                            updateOneCase(builder, updateCustomer);

                                            updateOneCase(builder, storeCustomer);

                                            // DELETE ONE
                                            deleteOneCase(builder, deleteCustomer);
                                        },
                                    });

export default componentsSlice.reducer;
