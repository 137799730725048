import {
    clearKeyword,
    clearSelection,
    setKeyword,
    setPage,
    setPageSize,
} from '../reducers';

import {
    deletePrice,
    fetchPrices,
} from '../reducers/recordsSlice';

import {
    recordsSelector,
    selectionSelector,
    visibilitySelector,
} from '../selectors';

import {columns} from '../tables/columns';
import {
    useAppDispatch,
    useAppSelector
} from '../../../hooks/useTypedReactRedux';

export const usePrices = () => {

    const dispatch = useAppDispatch();

    const selection = useAppSelector((state) => selectionSelector(state));

    const {
        items,
        visibleItems,
        limit,
        keyword,
        page,
        totalPages,
        visibleCount,
    } =
        useAppSelector((state) => visibilitySelector(state));

    const {loading} = useAppSelector((state) =>
                                         recordsSelector(state),
    );


    // FETCH
    const fetchRecords = () => {
        dispatch(fetchPrices());
    };

    const deleteRecords = () => {
        if (Object.keys(selection).length > 0) {
            for (const key in selection) {
                dispatch(deletePrice(key));
            }
        }
    };


    // FILTERING AND PAGING
    const handleFilterByKeyword = (keyword: string) => {
        if (keyword !== '') {
            dispatch(setKeyword(keyword));
        } else {
            dispatch(clearKeyword());
        }
    };

    const handleItemsPerPageChange = (pageSize: string | null) => {
        dispatch(setPageSize(pageSize));
    };

    const handlePageChange = (page: number) => {
        dispatch(setPage(page));
    };

    // BATCH ACTIONS
    const handleBatchActionCancel = () => {
        dispatch(clearSelection());
    };

    return {
        items,
        visibleItems,
        limit,
        loading,
        keyword,
        page,
        totalPages,
        visibleCount,
        columns,
        fetchRecords,
        deleteRecords,
        handleFilterByKeyword,
        handleItemsPerPageChange,
        handleBatchActionCancel,
        handlePageChange,
    };
};
