import {NavLink as ReactRouteNavLink} from 'react-router-dom';
import {useEffect} from "react";
import {useSubscriptions} from "../../../subscriptions";
import {
    DashboardWidgetBuilder,
    WidgetDataPoint,
    WidgetType
} from "@flexinet/ui-components";

export const SubscriptionsWidget = () => {

    const {
        items,
        fetchRecords
    } = useSubscriptions()

    useEffect(() => {
        fetchRecords()
    }, []);

    const dataPoints: WidgetDataPoint[] = [];
    dataPoints.push({
                        value: items.length,
                        label: 'Subscriptions',
                        color: 'flexinet',
                    });


    const widget: WidgetType = {
        header: {
            title: 'Subscriptions',
        },
        body: {
            dataPoints: dataPoints,
            metrics: [],
        },
        footer: {
            actionItems: [
                {
                    label: 'View All',
                    component: {ReactRouteNavLink},
                    to: '/subscriptions',
                    visible: true
                },
                {
                    label: 'Create Subscription',
                    component: {ReactRouteNavLink},
                    to: '/subscriptions/add',
                    visible: true,
                },
            ],
        }
    };

    return <DashboardWidgetBuilder widget={widget}/>;
};
