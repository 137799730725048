export const createPrice = `
    mutation createPrice($input: CreatePriceInput!) {
        createPrice(input:$input) {
            id
            currency
            product
            unit_amount
            active
            nickname
            billing_scheme
    }
}`;

export const updatePrice = `
    mutation updatePrice($input: UpdatePriceInput!) {
        updatePrice(input:$input){
            id
            currency
            product
            unit_amount
            active
            nickname
            billing_scheme
        }
}`;

export const deletePrice = `
    mutation deletePrice($input: DeletePriceInput!) {
        deletePrice(input:$input) 
}`;
