import {useEffect} from 'react';

import {useParams} from 'react-router-dom';

import {Stack} from '@mantine/core';

import {RecordForm} from '../forms/RecordForm';

import {
    FormPageLayout,
    PlatformLoader,
} from '@flexinet/ui-components';
import {Profile} from '../components/Profile';

import {EditPageNavigation} from '../components/EditPageNavigation';
import {useSubscription} from '../hooks/useSubscription';
import {useSubscriptionsSelection} from '../hooks/useSubscriptionsSelection';
import {useEditPageTools} from '../hooks/useEditPageTools';

export const EditPage = () => {

    const {id: componentId} = useParams();

    if (typeof componentId === 'undefined') {
        throw new Error('componentId is required parameter');
    }

    const {
        record: component,
        fetchRecord,
        updateRecord,
    } = useSubscription(componentId);

    const {handleClearSelection} = useSubscriptionsSelection();

    const {
        mode,
        tools,
    } = useEditPageTools();


    useEffect(() => {
        handleClearSelection();
    }, []);

    useEffect(() => {
        fetchRecord();
    }, [componentId]);

    if (!component) {
        return <PlatformLoader message='Loading application...Please wait'/>;
    }


    return (
        <FormPageLayout
            title={component.name ? component.name : component.id}
            pageNavigation={<EditPageNavigation component={component}/>}
            tools={tools}
        >


            {mode === 'view' ?
                <Stack gap='lg'>
                    <Profile
                        record={component}
                    />
                </Stack>
                :
                <RecordForm
                    record={component}
                    handleSubmit={updateRecord}
                />}


        </FormPageLayout>
    );
};
