export const listCards = `
   query listCards($customer: ID! ) {
       listCards(customer: $customer) {
           items {
              id
              customer
              number
              exp_month
              exp_year
              name
              brand
              country
           }
       }
   }
`;

export const getCard = `
   query getCard($customer: ID!, $id:ID!) {
       getCard(customer:$customer, id:$id) {
         id
         customer
         number
         exp_month
         exp_year
         name
         brand
         country
       } 
}`;
