import {I18n} from '@aws-amplify/core';

import {formatTimestamp} from '../../../utils/TableTimestamps';
import {NavLink} from 'react-router-dom';
import {Card} from '../types';

export const columns = [
    {
        title: I18n.get('Card'),
        width: '1fr',
        render: (item: Card) => <NavLink to={`/customers/${item.customer}/cards/${item.id}`}>{item.id}</NavLink>,
    },
    {
        title: I18n.get('Brand'),
        width: '1fr',
        render: (item: Card) => item.brand,
    },
    {
        title: I18n.get('Expiration'),
        width: '1fr',
        render: (item: Card) => item.exp_month + '/' + item.exp_year,
    },
    {
        title: I18n.get('Country'),
        width: '1fr',
        render: (item: Card) => item.country,
    },
    {
        title: I18n.get('Created'),
        width: '1fr',
        render: (item: Card) => formatTimestamp(item.created as string),
    },
];
