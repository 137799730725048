export const listProducts = `
   query listProducts {
       listProducts {
           items {
              id
              name
           }
       }
   }
`;

export const getProduct = `
   query getProduct($id:ID!) {
       getProduct(id:$id) {
         id
         name
       } 
}`;
