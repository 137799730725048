import {stage} from '../stage';


const platformAPI = {
    prod: {
        endpoint: 'https://jerup2o5nzej3hnn2sdv3ejtca.appsync-api.us-west-2.amazonaws.com/graphql',
        region: 'us-west-2',
        apiKey: '',
    },
    test: {
        endpoint: 'https://thz4u5ksajh6blvulnhiiddudi.appsync-api.us-west-2.amazonaws.com/graphql',
        region: 'us-west-2',
        apiKey: '',
    },
    dev: {
        endpoint: 'https://4wbjzuqnxbfobbgd2bo4mjbj5e.appsync-api.us-west-2.amazonaws.com/graphql',
        region: 'us-west-2',
        apiKey: '',
    },
    ide: {
        endpoint: 'https://4wbjzuqnxbfobbgd2bo4mjbj5e.appsync-api.us-west-2.amazonaws.com/graphql',
        region: 'us-west-2',
        apiKey: '',
    },
};

export const appSyncConfig = platformAPI[stage];

