import {
    deleteInvoiceItem,
    fetchInvoiceItem,
    saveInvoiceItem,
    updateInvoiceItem,
} from '../reducers/recordsSlice';
import {recordSelector} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from '../../../hooks/useTypedReactRedux';
import {InvoiceItem} from '../types';

export const useInvoiceItem = (invoiceId: string, invoiceItemId?: string) => {

    if (typeof invoiceId === 'undefined') {
        throw new Error('invoiceId is required parameter')
    }

    const dispatch = useAppDispatch();

    const {
        record,
        loading,
    } = useAppSelector((state) =>
                           recordSelector(state, invoiceItemId ? invoiceItemId : ''),
    );

    // FETCH
    const fetchRecord = () => {
        if (typeof invoiceItemId === 'undefined') {
            throw new Error('invoiceItemId is required parameter');
        }
        dispatch(fetchInvoiceItem({
                                      invoiceId: invoiceId,
                                      id: invoiceItemId
                                  }));
    };

    // CREATE, UPDATE DELETE
    const createRecord = (values: InvoiceItem) => {
        dispatch(saveInvoiceItem({
                                     invoiceId: invoiceId,
                                     payload: values
                                 }));
    };

    const updateRecord = (values: InvoiceItem) => {
        if (typeof invoiceItemId === 'undefined') {
            throw new Error('invoiceItemId is required parameter');
        }

        dispatch(updateInvoiceItem({
                                       invoiceId: invoiceId,
                                       id: invoiceItemId,
                                       record: values,
                                   }));
    };

    const deleteRecord = () => {
        if (typeof invoiceItemId === 'undefined') {
            throw new Error('invoiceItemId is required parameter');
        }
        dispatch(deleteInvoiceItem({
                                       invoiceId: invoiceId,
                                       id: invoiceItemId
                                   }));
    };

    return {
        record,
        loading,
        fetchRecord,
        createRecord,
        updateRecord,
        deleteRecord,
    };
};
