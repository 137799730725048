import {
    Outlet,
    useNavigate
} from 'react-router-dom';


import {
    PlatformAppShell,
    PlatformHeader,
    PlatformHeaderToolbar,
    PlatformLoader
} from '@flexinet/ui-components';


import {Suspense} from "react";
import {useUserAttributes} from "../hooks/useUserAttributes.ts";
import {AiOutlineLogout} from "react-icons/ai";
import config from "../config";
import {
    Box,
    useMantineColorScheme
} from "@mantine/core";
import {PortalNavbar} from "./menu-items/pages/PortalNavbar.tsx";

import classes from './AppLayout.module.css';

export const ColorSchemeWrapper = ({children}: { children: React.ReactNode }) => {
    const {setColorScheme} = useMantineColorScheme();
    const handleColorScheme = (value: boolean) => setColorScheme(value ? 'dark' : 'light');
    console.debug("ColorSchemeWrapper", handleColorScheme)
    // useEffect(() => {
    //     channel.on(DARK_MODE_EVENT_NAME, handleColorScheme);
    //     return () => channel.off(DARK_MODE_EVENT_NAME, handleColorScheme);
    // }, [channel]);

    return <>{children}</>;
}


export const AppLayout = () => {
    const navigate = useNavigate()

    const {
        givenName,
        familyName,
        email
    } = useUserAttributes()

    let userInfo = undefined
    if (givenName) {
        userInfo = {
            name: [givenName, familyName].join(' '),
            email: email
        }
    }

    const additionalMenuItems = [{
        title: 'Sign Out',
        link: '/sign-out',
        onClick: () => navigate('/sign-out'),
        icon: <AiOutlineLogout/>
    }]

    return (
        <PlatformAppShell
            platformNavbar={<PortalNavbar/>}
            platformHeader={
                <PlatformHeader
                    toolbar={<PlatformHeaderToolbar
                        user={userInfo ? userInfo : undefined}
                        portalUrl={config.platform.portal}
                        additionalMenuItems={additionalMenuItems}/>}/>
            }
        >

            <Suspense fallback={<PlatformLoader/>}>
                <Box className={classes.main}>
                    <Outlet/>
                </Box>
            </Suspense>


        </PlatformAppShell>
    );


};
