import {I18n} from '@aws-amplify/core';

import {formatTimestamp} from '../../../utils/TableTimestamps';
import {NavLink} from 'react-router-dom';
import {Price} from '../types';

export const columns = [
    {
        title: I18n.get('Price'),
        width: '1fr',
        render: (item: Price) => <NavLink to={`/prices/${item.id}`}>{item.id}</NavLink>,
    },
    {
        title: I18n.get('Product'),
        width: '1fr',
        render: (item: Price) => item.product,
    },
    {
        title: I18n.get('Unit Amount'),
        width: '1fr',
        render: (item: Price) => item.unit_amount,
    },
    {
        title: I18n.get('Currency'),
        width: '1fr',
        render: (item: Price) => item.currency,
    },
    {
        title: I18n.get('Billing Scheme'),
        width: '1fr',
        render: (item: Price) => item.billing_scheme,
    },
    {
        title: I18n.get('Created'),
        width: '1fr',
        render: (item: Price) => formatTimestamp(item.created as string),
    },
];
