import {
    createBrowserRouter,
    RouterProvider
} from 'react-router-dom';
import {PublicHomePage} from './features/home/pages/PublicHomePage';
import {SignOutPage} from './features/home/pages/SignOutPage';

import {IndexPage as DashboardPage} from "./features/dashboard/pages/IndexPage";
import {AppLayout} from "./layout/AppLayout";

import {Authenticator} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import {RequireAuth} from "./utils/RequireAuth.tsx";
import {CustomersLayout} from "./features/customers/layout.tsx";
import {UserAttributesContextProvider} from "./context/UserAttributesContext.tsx";
import {InvoicesLayout} from "./features/invoices/layout.tsx";
import {SubscriptionsLayout} from "./features/subscriptions/layout.tsx";
import {ProductsLayout} from "./features/products/layout.tsx";
import {PricesLayout} from "./features/prices/layout.tsx";
import {CardsLayout} from "./features/cards/layout.tsx";
import {BankAccountsLayout} from "./features/bank-accounts/layout.tsx";

import {Elements} from "@stripe/react-stripe-js";
import {
    loadStripe,
    StripeElementsOptions
} from "@stripe/stripe-js";
import {theme} from "./theme.ts";
import {MantineProvider} from "@mantine/core";
import {Notifications} from "@mantine/notifications";
import {ModalsProvider} from "@mantine/modals";

import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';

const router = createBrowserRouter([{
    path: '/',
    element: <AppLayout/>,
    children: [{
        index: true,
        element: <PublicHomePage/>
    }, {
        path: 'sign-out',
        element: <SignOutPage/>
    }, {
        path: 'dashboard',
        element: <RequireAuth><DashboardPage/></RequireAuth>
    }, {
        path: 'customers/*',
        element: <RequireAuth><CustomersLayout/></RequireAuth>
    }, {
        path: 'cards/*',
        element: <RequireAuth><CardsLayout/></RequireAuth>
    }, {
        path: 'bank-accounts/*',
        element: <RequireAuth><BankAccountsLayout/></RequireAuth>
    }, {
        path: 'invoices/*',
        element: <RequireAuth><InvoicesLayout/></RequireAuth>
    }, {
        path: 'subscriptions/*',
        element: <RequireAuth><SubscriptionsLayout/></RequireAuth>
    }, {
        path: 'prices/*',
        element: <RequireAuth><PricesLayout/></RequireAuth>
    }, {
        path: 'products/*',
        element: <RequireAuth><ProductsLayout/></RequireAuth>
    }]
}])


// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
    'pk_test_51J9MGPHi5nXRQeoomKKMfIBELXcB97ZeQRZgwZfXQoRwIDpjM5mQk7fv4wKhBq7630bWymsAX7acxtJnjPi4a2gF00a13mABrs'
);

export const App = () => {


    const options = {
        // passing the client secret obtained from the server
        // clientSecret: '{{CLIENT_SECRET}}',
        appearance: {
            theme: 'stripe',
            rules: {
                '.Input': {
                    border: '1px solid #E0E6EB',
                }
            }
        }
    } as StripeElementsOptions;

    return (
        <MantineProvider theme={theme}>
            <Elements stripe={stripePromise} options={options}>
                <Authenticator.Provider>
                    <UserAttributesContextProvider>
                        <ModalsProvider>
                            <Notifications/>
                            <RouterProvider router={router}/>
                        </ModalsProvider>
                    </UserAttributesContextProvider>
                </Authenticator.Provider>
            </Elements>
        </MantineProvider>
    );
}

