import PropTypes from "prop-types";
import {I18n} from "@aws-amplify/core";

import {Center} from "@mantine/core";

import {RemoveRowButtonProps} from "./types";
import {FlexiDeleteButton} from "@flexinet/ui-components";


export const RemoveRowButton = ({
                                    remove,
                                    rowIndex
                                }: RemoveRowButtonProps) => {

    return (
        <Center>
            <FlexiDeleteButton
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                    event.preventDefault();
                    remove(rowIndex);
                }}

            >{I18n.get("Remove")}</FlexiDeleteButton>
        </Center>
    );
};


RemoveRowButton.propTypes = {
    remove: PropTypes.func,
    rowIndex: PropTypes.number
}


