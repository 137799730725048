import {
    getPrice,
    listPrices
} from './queries';
import {
    createPrice,
    deletePrice,
    updatePrice,
} from './mutations';

import {GraphQLQuery,} from '@aws-amplify/api';

import {API} from 'aws-amplify';
import {Price} from "../types.ts";


export type ListPrices = {
    listPrices: { items: Price[] }
}

export type GetPrice = {
    getPrice: Price
}

export type CreatePrice = {
    createPrice: Price
}

export type UpdatePrice = {
    updatePrice: Price
}

export type DeletePrice = {
    deletePrice: string
}


export const fetchRecords = async (filter: object) => {

    try {
        const response = await API.graphql<GraphQLQuery<ListPrices>>({
                                                                         query: listPrices,
                                                                         variables: {filter: filter},
                                                                     });
        console.debug('in the fetch', response)

        return response.data?.listPrices.items;
    } catch (e) {
        console.trace(e);
    }
};

export const fetch = async (id: string) => {
    try {
        const response = await API.graphql<GraphQLQuery<GetPrice>>({
                                                                       query: getPrice,
                                                                       variables: {id: id},
                                                                   });
        return response.data?.getPrice;
    } catch (e) {
        console.trace(e);
    }
};

export const add = async (payload: object) => {

    try {
        const response = await API.graphql<GraphQLQuery<CreatePrice>>({
                                                                          query: createPrice,
                                                                          variables: {input: payload},
                                                                      });
        console.debug('in the service response', response);

        return response.data?.createPrice;
    } catch (e) {
        console.trace(e);
    }
};

export const update = async (id: string, record: Price) => {

    console.debug('IN UPDATE', id, record);

    delete record.created;
    delete record.updated;

    try {
        const response = await API.graphql<GraphQLQuery<UpdatePrice>>({
                                                                          query: (updatePrice),
                                                                          variables: {input: record},
                                                                      });

        return response.data?.updatePrice;
    } catch (e) {
        console.trace(e);
    }
};

export const deleteRecord = async (id: string) => {
    try {
        const response = await API.graphql<GraphQLQuery<DeletePrice>>({
                                                                          query: (deletePrice),
                                                                          variables: {input: {id: id}},

                                                                      });
        return response.data?.deletePrice;
    } catch (e) {
        console.trace(e);
    }
};
