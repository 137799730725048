import PropTypes from 'prop-types';
import {Field} from 'formik';

import {SimpleGrid} from '@mantine/core';
import {FormikNumberInput} from "@flexinet/ui-components";

import {I18n} from "@aws-amplify/core";
import {FormikList} from "../../../components/FormikList";
import {PricesAutocomplete} from "../../prices/inputs/PricesAutocomplete.tsx";
import {CustomersAutocomplete} from "../../customers/inputs/CustomersAutocomplete.tsx";


const itemsListRowElements = [
    {
        name: 'price',
        placeholder: I18n.get('Price'),
        component: PricesAutocomplete,
        colspan: 6,
    },
    {
        name: 'quantity',
        placeholder: I18n.get('Quantity'),
        component: FormikNumberInput,
        colspan: 6,
    },

]


export const FormComponent = () => {


    return (
        <SimpleGrid cols={1}>

            <Field
                name='customer'
                label='Customer'
                description='Please enter the customer'
                component={CustomersAutocomplete}
                withAsterisk
            />

            <Field
                name='items'
                label='Items'
                description='Please enter the Items'
                component={FormikList}
                getRowElements={() => {
                    return itemsListRowElements
                }}
                withAsterisk
            />

        </SimpleGrid>
    );
};

FormComponent.propTypes = {
    values: PropTypes.object.isRequired,
};
