import {
    addToSelection,
    clearSelection,
    removeFromSelection,
} from '../reducers';

import {
    selectionSelector,
    visibilitySelector,
} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from '../../../hooks/useTypedReactRedux';


export const useInvoicesSelection = () => {
    const dispatch = useAppDispatch();

    const selection = useAppSelector((state) => selectionSelector(state));

    const {items} =
        useAppSelector((state) => visibilitySelector(state));


    // SELECTION
    const handleRowSelection = (row: { id: string }) => {
        if (typeof selection[row.id] !== 'undefined') {
            dispatch(removeFromSelection(row.id));
        } else {
            dispatch(addToSelection(row));
        }
    };

    const handleSelectAllRows = () => {
        for (let i = 0; i < items.length; i++) {
            if (typeof selection[items[i].id] !== 'undefined') {
                dispatch(removeFromSelection(items[i].id));
            } else {
                dispatch(addToSelection(items[i]));
            }
        }
    };

    const handleClearSelection = () => {
        dispatch(clearSelection());
    };

    return {
        items,
        selection,
        handleRowSelection,
        handleSelectAllRows,
        handleClearSelection,
    };
};
