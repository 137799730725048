import PropTypes from "prop-types";
import {I18n} from "@aws-amplify/core";

import {Group} from "@mantine/core";

import {AddRowButtonProps} from "./types";
import {FlexiAddButton} from "@flexinet/ui-components";


export const AddRowButton = ({
                                 row,
                                 push,
                             }: AddRowButtonProps) => {


    return (
        <Group>
            <FlexiAddButton
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.preventDefault();
                    push(row);
                }}
            >
                {I18n.get("Add")}
            </FlexiAddButton>
        </Group>
    );
};


AddRowButton.propTypes = {
    name: PropTypes.string,
    values: PropTypes.object,
    push: PropTypes.func,
    getRowElements: PropTypes.func
}