import {
    getInvoiceItem,
    listInvoiceItems
} from './queries';
import {
    createInvoiceItem,
    deleteInvoiceItem,
    updateInvoiceItem,
} from './mutations';

import {GraphQLQuery,} from '@aws-amplify/api';

import {API} from 'aws-amplify';
import {InvoiceItem} from "../types.ts";


export type ListInvoiceItems = {
    listInvoiceItems: { items: InvoiceItem[] }
}

export type GetInvoiceItem = {
    getInvoiceItem: InvoiceItem
}

export type CreateInvoiceItem = {
    createInvoiceItem: InvoiceItem
}

export type UpdateInvoiceItem = {
    updateInvoiceItem: InvoiceItem
}

export type DeleteInvoiceItem = {
    deleteInvoiceItem: string
}


export const fetchRecords = async (filter: object) => {

    try {
        const response = await API.graphql<GraphQLQuery<ListInvoiceItems>>({
                                                                               query: listInvoiceItems,
                                                                               variables: {filter: filter},
                                                                           });
        return response.data?.listInvoiceItems.items;
    } catch (e) {
        console.trace(e);
    }
};

export const fetch = async (invoiceId: string, id: string) => {
    try {
        const response = await API.graphql<GraphQLQuery<GetInvoiceItem>>({
                                                                             query: getInvoiceItem,
                                                                             variables: {
                                                                                 invoice: invoiceId,
                                                                                 id: id
                                                                             },
                                                                         });
        return response.data?.getInvoiceItem;
    } catch (e) {
        console.trace(e);
    }
};

export const add = async (payload: object) => {

    try {
        const response = await API.graphql<GraphQLQuery<CreateInvoiceItem>>({
                                                                                query: createInvoiceItem,
                                                                                variables: {input: payload},
                                                                            });
        console.debug('in the service response', response);

        return response.data?.createInvoiceItem;
    } catch (e) {
        console.trace(e);
    }
};

export const update = async (record: InvoiceItem) => {

    delete record.created;
    delete record.updated;

    try {
        const response = await API.graphql<GraphQLQuery<UpdateInvoiceItem>>({
                                                                                query: (updateInvoiceItem),
                                                                                variables: {input: record},
                                                                            });

        return response.data?.updateInvoiceItem;
    } catch (e) {
        console.trace(e);
    }
};

export const deleteRecord = async (invoiceId: string, id: string) => {
    try {
        const response = await API.graphql<GraphQLQuery<DeleteInvoiceItem>>({
                                                                                query: (deleteInvoiceItem),
                                                                                variables: {
                                                                                    input: {
                                                                                        invoice: invoiceId,
                                                                                        id: id
                                                                                    }
                                                                                },

                                                                            });
        return response.data?.deleteInvoiceItem;
    } catch (e) {
        console.trace(e);
    }
};
