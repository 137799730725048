export const createSubscriptionItem = `
    mutation createSubscriptionItem($input: CreateSubscriptionItemInput!) {
        createSubscriptionItem(input:$input) {
          id
          customer
          country
          currency
          account_holder_name
          account_holder_type
          bank_name
          routing_number
    }
}`;

export const updateSubscriptionItem = `
    mutation updateSubscriptionItem($input: UpdateSubscriptionItemInput!) {
        updateSubscriptionItem(input:$input){
          id
          customer
          country
          currency
          account_holder_name
          account_holder_type
          bank_name
          routing_number
        }
}`;

export const deleteSubscriptionItem = `
    mutation deleteSubscriptionItem($input: DeleteSubscriptionItemInput!) {
        deleteSubscriptionItem(input:$input) 
}`;
