import {
    deleteBankAccount,
    fetchBankAccount,
    saveBankAccount,
    updateBankAccount,
} from '../reducers/recordsSlice';
import {recordSelector} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from '../../../hooks/useTypedReactRedux';
import {BankAccount} from '../types';

export const useBankAccount = (customerId: string, bankAccountId?: string) => {

    if (typeof customerId === 'undefined') {
        throw new Error('customerId is required parameter')
    }

    const dispatch = useAppDispatch();

    const {
        record,
        loading,
    } = useAppSelector((state) =>
                           recordSelector(state, bankAccountId ? bankAccountId : ''),
    );

    // FETCH
    const fetchRecord = () => {
        if (typeof bankAccountId === 'undefined') {
            throw new Error('bankAccountId is required parameter');
        }
        dispatch(fetchBankAccount({
                                      customerId: customerId,
                                      id: bankAccountId
                                  }));
    };

    // CREATE, UPDATE DELETE
    const createRecord = (values: BankAccount) => {
        dispatch(saveBankAccount({
                                     customerId: customerId,
                                     payload: values
                                 }));
    };

    const updateRecord = (values: BankAccount) => {
        if (typeof bankAccountId === 'undefined') {
            throw new Error('bankAccountId is required parameter');
        }

        dispatch(updateBankAccount({
                                       customerId: customerId,
                                       id: bankAccountId,
                                       record: values,
                                   }));
    };

    const deleteRecord = () => {
        if (typeof bankAccountId === 'undefined') {
            throw new Error('bankAccountId is required parameter');
        }
        dispatch(deleteBankAccount({
                                       customerId: customerId,
                                       id: bankAccountId
                                   }));
    };

    return {
        record,
        loading,
        fetchRecord,
        createRecord,
        updateRecord,
        deleteRecord,
    };
};
