import {Navigate} from "react-router-dom";
import {useAuthenticator} from "@aws-amplify/ui-react";
import {PlatformLoader} from "@flexinet/ui-components";
import config from "../../../config";

export const PublicHomePage = () => {

    const {authStatus} = useAuthenticator();

    switch (authStatus) {
        case "authenticated":
            return <Navigate to={'/dashboard'}/>;
        case "unauthenticated":
            window.location.assign(config.platform.portal)
            break;
        case "configuring":
            return <PlatformLoader message={'Loading...Please, wait'}/>
    }


};
