export const createInvoice = `
    mutation createInvoice($input: CreateInvoiceInput!) {
        createInvoice(input:$input) {
            id
            customer
    }
}`;

export const updateInvoice = `
    mutation updateInvoice($input: UpdateInvoiceInput!) {
        updateInvoice(input:$input){
            id
            customer
        }
}`;

export const deleteInvoice = `
    mutation deleteInvoice($input: DeleteInvoiceInput!) {
        deleteInvoice(input:$input) 
}`;
