import PropTypes from 'prop-types';
import {Formik} from 'formik';

import {FormComponent} from './FormComponent';

import {RecordSchema} from './RecordSchema';

import {BankAccount} from '../types';
import {FormWithButtons} from "@flexinet/ui-components";
import {stage} from "../../../config/stage.ts";
import {
    useElements,
    useStripe
} from "@stripe/react-stripe-js";
import {useNavigate} from "react-router-dom";
import {StripeIbanElement} from "@stripe/stripe-js";

export const RecordForm = ({
                               record,
                               handleSubmit,
                           }: {
    record: Omit<BankAccount, 'id'>,
    handleSubmit: (values: BankAccount) => void
}) => {

    const navigate = useNavigate()
    const stripe = useStripe();
    const elements = useElements();


    const onSubmit = async (values: BankAccount) => {


        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const ibanElement = elements.getElement('iban') as StripeIbanElement
        if (!ibanElement) {
            throw new Error('iban element is require parameter')
        }

        // @ts-ignore
        const result = await stripe.createToken(ibanElement, {
            // country: values.country,
            // account_number: values.account_number,

            currency: values.currency,
            routing_number: values.routing_number,
            account_holder_name: values.account_holder_name,
            account_holder_type: values.account_holder_type
        })

        console.debug(result)

        if (typeof result.token === 'undefined') {
            throw new Error('token is required parameter')
        }

        if (typeof result.token.bank_account === 'undefined') {
            throw new Error('token bank_account is required parameter')
        }

        const payload = {
            id: '',
            source: result.token.bank_account.id
        } as BankAccount

        handleSubmit(payload);
        navigate(`/customers/${record.customer}`);

    };

    const onCancel = () => {
        navigate(`/customers/${record.customer}`);
    };


    let initialValues = {
        currency: 'usd',
        country: 'US'
    } as BankAccount;

    if (record) {
        initialValues = Object.assign({}, initialValues, record);
    }


    return (
        <Formik
            initialValues={initialValues}
            validationSchema={RecordSchema}
            onSubmit={onSubmit}
        >
            {({
                  handleSubmit,
                  isSubmitting,
                  ...rest
              }) => (
                <FormWithButtons handleSubmit={handleSubmit} isSubmitting={isSubmitting} handleCancel={onCancel}
                                 debug={false && stage === 'ide'} {...rest}  >
                    <FormComponent {...rest} />
                </FormWithButtons>
            )}


        </Formik>
    );
};

RecordForm.propTypes = {
    record: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
};
