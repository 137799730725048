import {
    getSubscriptionItem,
    listSubscriptionItems
} from './queries';
import {
    createSubscriptionItem,
    deleteSubscriptionItem,
    updateSubscriptionItem,
} from './mutations';

import {GraphQLQuery,} from '@aws-amplify/api';

import {API} from 'aws-amplify';
import {SubscriptionItem} from "../types.ts";


export type ListSubscriptionItems = {
    listSubscriptionItems: { items: SubscriptionItem[] }
}

export type GetSubscriptionItem = {
    getSubscriptionItem: SubscriptionItem
}

export type CreateSubscriptionItem = {
    createSubscriptionItem: SubscriptionItem
}

export type UpdateSubscriptionItem = {
    updateSubscriptionItem: SubscriptionItem
}

export type DeleteSubscriptionItem = {
    deleteSubscriptionItem: string
}


export const fetchRecords = async (customerId: string) => {

    try {
        const response = await API.graphql<GraphQLQuery<ListSubscriptionItems>>({
                                                                                    query: listSubscriptionItems,
                                                                                    variables: {customer: customerId},
                                                                                });
        return response.data?.listSubscriptionItems.items;
    } catch (e) {
        console.trace(e);
    }
};

export const fetch = async (customerId: string, id: string) => {
    try {
        const response = await API.graphql<GraphQLQuery<GetSubscriptionItem>>({
                                                                                  query: getSubscriptionItem,
                                                                                  variables: {
                                                                                      customer: customerId,
                                                                                      id: id
                                                                                  },
                                                                              });
        return response.data?.getSubscriptionItem;
    } catch (e) {
        console.trace(e);
    }
};

export const add = async (payload: object) => {

    try {
        const response = await API.graphql<GraphQLQuery<CreateSubscriptionItem>>({
                                                                                     query: createSubscriptionItem,
                                                                                     variables: {input: payload},
                                                                                 });
        console.debug('in the service response', response);

        return response.data?.createSubscriptionItem;
    } catch (e) {
        console.trace(e);
    }
};

export const update = async (record: SubscriptionItem) => {

    delete record.created;
    delete record.updated;

    try {
        const response = await API.graphql<GraphQLQuery<UpdateSubscriptionItem>>({
                                                                                     query: (updateSubscriptionItem),
                                                                                     variables: {input: record},
                                                                                 });

        return response.data?.updateSubscriptionItem;
    } catch (e) {
        console.trace(e);
    }
};

export const deleteRecord = async (customerId: string, id: string) => {
    try {
        const response = await API.graphql<GraphQLQuery<DeleteSubscriptionItem>>({
                                                                                     query: (deleteSubscriptionItem),
                                                                                     variables: {
                                                                                         input: {
                                                                                             customer: customerId,
                                                                                             id: id
                                                                                         }
                                                                                     },

                                                                                 });
        return response.data?.deleteSubscriptionItem;
    } catch (e) {
        console.trace(e);
    }
};
