import {ReactNode} from 'react';
import PropTypes from 'prop-types';
import {useAuthenticator} from "@aws-amplify/ui-react";
import {PlatformLoader} from "@flexinet/ui-components";


export const RequireAuth = ({children}: { children: ReactNode }) => {
    const {authStatus} = useAuthenticator();
    if (authStatus !== 'authenticated') {

        // return <Navigate to="/"/>;
        return <PlatformLoader message={'Authenticating...Please, wait!'}/>;

    }
    return children;
};

RequireAuth.propTypes = {
    children: PropTypes.node,
};
