import {stage} from './stage';

const platform = {
    prod: {
        portal: 'https://portal.flexi.network',
        console: 'https://console.flexi.network',
        marketplace: 'https://marketplace.flexi.network',
    },
    test: {
        portal: 'https://portal.test.flexi.network',
        console: 'https://console.test.flexi.network',
        marketplace: 'https://marketplace.test.flexi.network',
    },
    dev: {
        portal: 'https://portal.dev.flexi.network',
        console: 'https://console.dev.flexi.network',
        marketplace: 'https://marketplace.dev.flexi.network',
    },
    ide: {
        portal: 'https://localhost:8081',
        console: 'https://localhost:8082',
        marketplace: 'https://localhost:8093',
    },
};

export default platform[stage];
