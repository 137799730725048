import {
    ReactElement,
    useEffect,
} from 'react';

import {
    Center,
    Checkbox,
    SimpleGrid,
    Table,
} from '@mantine/core';

import {useProducts} from '../hooks/useProducts';

import {
    RowSelectionDataCell,
    SimpleTableBatchAction,
    SimpleTablePagination,
    TableBody,
    TableBodyColumn,
    TableHeaderColumn,
    TableHeaderRow,
} from '@flexinet/ui-components';

import PropTypes from 'prop-types';
import {useAccessControl} from '../../../hooks/useAccessControl';
import {useProductsSelection} from '../hooks/useProductsSelection';
import {IProductTableColumn,} from '../types';
import {useAuthenticator} from "@aws-amplify/ui-react";
import {useUserAttributes} from "../../../hooks/useUserAttributes.ts";

export const RecordsTable = ({
                                 extraTools,
                                 settings,
                             }: {
    extraTools?: ReactElement[],
    settings?: { label: string, onClick: () => void }[]
}) => {


    const {
        user
    } = useAuthenticator();


    if (!user) {
        throw new Error('user is required parameter')
    }

    const cognitoUsername = user.getUsername();

    const {role} = useUserAttributes();

    const {
        evaluateAction,
        userRoles
    } = useAccessControl(role)


    const isAdmin = userRoles.includes('Admin');

    const {
        columns,
        visibleItems,
        limit,
        loading,
        keyword,
        totalPages,
        visibleCount,
        fetchRecords,
        handleFilterByKeyword,
        handleItemsPerPageChange,
        handlePageChange,
    } = useProducts();


    const {
        selection,
        handleRowSelection,
        handleSelectAllRows,
    } = useProductsSelection();

    useEffect(() => {
        fetchRecords();
    }, []);


    const getColumns = () => {
        let cols: IProductTableColumn[] = [];
        if (evaluateAction('DeleteProduct')) {

            cols.push({
                          id: 'select',
                          title: '',
                          render: item => {
                              if (isAdmin || item.createdByUsername === cognitoUsername || item.managedByUsername === cognitoUsername) {
                                  return <RowSelectionDataCell item={item} handleChange={handleRowSelection}/>;
                              } else {
                                  return (<Center><Checkbox disabled={true}>n/a</Checkbox></Center>);
                              }

                          },


                      },
            );
        }

        cols = cols.concat(columns);

        return cols;

    };


    return (
        <SimpleGrid verticalSpacing='xl' m='sm'>
            <SimpleTableBatchAction
                limit={limit}
                handleItemsPerPageChange={handleItemsPerPageChange}
                settings={settings}
                keyword={keyword}
                handleFilterByKeyword={handleFilterByKeyword}
                extraTools={extraTools}
            />
            <Table verticalSpacing='sm' striped highlightOnHover>
                <TableHeaderRow
                    columns={getColumns() as TableHeaderColumn[]}
                    handleSelectAllRows={handleSelectAllRows}
                />
                <TableBody
                    items={visibleItems}
                    loading={loading}
                    selection={selection}
                    columns={getColumns() as TableBodyColumn[]}
                />
            </Table>

            <SimpleTablePagination
                totalPages={totalPages}
                visibleCount={visibleCount}
                handlePageChange={handlePageChange}
            />
        </SimpleGrid>
    );
};

RecordsTable.propTypes = {
    extraTools: PropTypes.array,
    settings: PropTypes.object,
};