import {
    Grid,
    Title
} from "@mantine/core";

import {
    Field,
    FieldProps
} from "formik";

import {ListRowElement} from "./types";


const renderElementWithLabel = (element: ListRowElement, fieldName: string) => {

    return (
        <Grid key={element.name}>
            <Grid.Col span={4}>
                <Field {...element} name={fieldName}/>
            </Grid.Col>
            <Grid.Col span={8}>
                {element.label && (
                    <Title order={4}>
                        {element.label}
                    </Title>
                )}
            </Grid.Col>
        </Grid>
    );
};


export const ListRow = ({
                            field,
                            rowIndex,
                            rowElements
                        }: FieldProps & { rowIndex: number, rowElements: ListRowElement[] }) => {

    // calculate grid definition
    const defaultColspan = Math.floor(12 / rowElements.length);

    return (
        <Grid grow>
            {rowElements.map((element, elementIndex: number) => {
                const fieldName = `${field.name}.${rowIndex}.${element.name}`;

                return (
                    <Grid.Col key={elementIndex} span={element.span ? element.span : defaultColspan}>
                        {(typeof element.label !== "undefined" && element.label.length > 0) ? renderElementWithLabel(element, fieldName) :
                            <Field {...element} key={element.name} name={fieldName}/>}
                    </Grid.Col>
                );
            })}
        </Grid>
    );
};





