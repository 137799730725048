import * as Yup from 'yup';

export const RecordSchema = Yup.object()
                               .shape({
                                          account_number: Yup.string()
                                                             .required('Required'),
                                          account_holder_name: Yup.string()
                                                                  .required('Required'),
                                          account_holder_type: Yup.string()
                                                                  .required('Required'),
                                          country: Yup.string()
                                                      .required('Required'),
                                          currency: Yup.string()
                                                       .required('Required'),
                                      });
