import {
    getProduct,
    listProducts
} from './queries';
import {
    createProduct,
    deleteProduct,
    updateProduct,
} from './mutations';

import {GraphQLQuery,} from '@aws-amplify/api';

import {API} from 'aws-amplify';
import {Product} from "../types.ts";


export type ListProducts = {
    listProducts: { items: Product[] }
}

export type GetProduct = {
    getProduct: Product
}

export type CreateProduct = {
    createProduct: Product
}

export type UpdateProduct = {
    updateProduct: Product
}

export type DeleteProduct = {
    deleteProduct: string
}


export const fetchRecords = async (filter: object) => {

    try {
        const response = await API.graphql<GraphQLQuery<ListProducts>>({
                                                                           query: listProducts,
                                                                           variables: {filter: filter},
                                                                       });
        console.debug('in the fetch', response)

        return response.data?.listProducts.items;
    } catch (e) {
        console.trace(e);
    }
};

export const fetch = async (id: string) => {
    try {
        const response = await API.graphql<GraphQLQuery<GetProduct>>({
                                                                         query: getProduct,
                                                                         variables: {id: id},
                                                                     });
        return response.data?.getProduct;
    } catch (e) {
        console.trace(e);
    }
};

export const add = async (payload: object) => {

    try {
        const response = await API.graphql<GraphQLQuery<CreateProduct>>({
                                                                            query: createProduct,
                                                                            variables: {input: payload},
                                                                        });
        console.debug('in the service response', response);

        return response.data?.createProduct;
    } catch (e) {
        console.trace(e);
    }
};

export const update = async (id: string, record: Product) => {

    console.debug('IN UPDATE', id, record);

    delete record.created;
    delete record.updated;

    try {
        const response = await API.graphql<GraphQLQuery<UpdateProduct>>({
                                                                            query: (updateProduct),
                                                                            variables: {input: record},
                                                                        });

        return response.data?.updateProduct;
    } catch (e) {
        console.trace(e);
    }
};

export const deleteRecord = async (id: string) => {
    try {
        const response = await API.graphql<GraphQLQuery<DeleteProduct>>({
                                                                            query: (deleteProduct),
                                                                            variables: {input: {id: id}},

                                                                        });
        return response.data?.deleteProduct;
    } catch (e) {
        console.trace(e);
    }
};
