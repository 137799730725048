import {
    getCustomer,
    listCustomers
} from './queries';
import {
    createCustomer,
    deleteCustomer,
    updateCustomer,
} from './mutations';

import {GraphQLQuery,} from '@aws-amplify/api';

import {API} from 'aws-amplify';
import {Customer} from "../types.ts";


export type ListCustomers = {
    listCustomers: { items: Customer[] }
}

export type GetCustomer = {
    getCustomer: Customer
}

export type CreateCustomer = {
    createCustomer: Customer
}

export type UpdateCustomer = {
    updateCustomer: Customer
}

export type DeleteCustomer = {
    deleteCustomer: string
}


export const fetchRecords = async (filter: object) => {

    try {
        const response = await API.graphql<GraphQLQuery<ListCustomers>>({
                                                                            query: listCustomers,
                                                                            variables: {filter: filter},
                                                                        });
        return response.data?.listCustomers.items;
    } catch (e) {
        console.trace(e);
    }
};

export const fetch = async (id: string) => {
    try {
        const response = await API.graphql<GraphQLQuery<GetCustomer>>({
                                                                          query: getCustomer,
                                                                          variables: {id: id},
                                                                      });
        return response.data?.getCustomer;
    } catch (e) {
        console.trace(e);
    }
};

export const add = async (payload: object) => {

    try {
        const response = await API.graphql<GraphQLQuery<CreateCustomer>>({
                                                                             query: createCustomer,
                                                                             variables: {input: payload},
                                                                         });
        console.debug('in the service response', response);

        return response.data?.createCustomer;
    } catch (e) {
        console.trace(e);
    }
};

export const update = async (id: string, record: Customer) => {

    console.debug('IN UPDATE', id, record);

    delete record.created;
    delete record.updated;

    try {
        const response = await API.graphql<GraphQLQuery<UpdateCustomer>>({
                                                                             query: (updateCustomer),
                                                                             variables: {input: record},
                                                                         });

        return response.data?.updateCustomer;
    } catch (e) {
        console.trace(e);
    }
};

export const deleteRecord = async (id: string) => {
    try {
        const response = await API.graphql<GraphQLQuery<DeleteCustomer>>({
                                                                             query: (deleteCustomer),
                                                                             variables: {input: {id: id}},

                                                                         });
        return response.data?.deleteCustomer;
    } catch (e) {
        console.trace(e);
    }
};
