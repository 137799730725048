export const createBankAccount = `
    mutation createBankAccount($input: CreateBankAccountInput!) {
        createBankAccount(input:$input) {
          id
          customer
          country
          currency
          account_holder_name
          account_holder_type
          bank_name
          routing_number
    }
}`;

export const updateBankAccount = `
    mutation updateBankAccount($input: UpdateBankAccountInput!) {
        updateBankAccount(input:$input){
          id
          customer
          country
          currency
          account_holder_name
          account_holder_type
          bank_name
          routing_number
        }
}`;

export const deleteBankAccount = `
    mutation deleteBankAccount($input: DeleteBankAccountInput!) {
        deleteBankAccount(input:$input) 
}`;
