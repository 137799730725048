export const createSubscription = `
    mutation createSubscription($input: CreateSubscriptionInput!) {
        createSubscription(input:$input) {
            id
            customer
            items {
                id
              }
            description
        }
}`;

export const updateSubscription = `
    mutation updateSubscription($input: UpdateSubscriptionInput!) {
        updateSubscription(input:$input){
            id
            customer
            items {
                id
              }
            description
        }
}`;

export const deleteSubscription = `
    mutation deleteSubscription($input: DeleteSubscriptionInput!) {
        deleteSubscription(input:$input) 
}`;
