import {RecordForm} from '../forms/RecordForm';


import {useCard} from '../hooks/useCard.ts';
import {FormPageLayout} from "@flexinet/ui-components";
import {useParams} from "react-router-dom";
import {AddPageNavigation} from "../components/BreadcrumbsNavigation.tsx";


export const AddPage = () => {

    const {id: customerId} = useParams();

    if (typeof customerId === 'undefined') {
        throw new Error('customerId is required parameter')
    }

    const {
        createRecord,
    } = useCard(customerId);


    return (
        <FormPageLayout
            title='Add Card'
            pageNavigation={<AddPageNavigation/>}
        >
            <RecordForm record={{customer: customerId}} handleSubmit={createRecord}/>
        </FormPageLayout>
    );
};
