import {config as API} from './api';
import {config as Auth} from './auth';
import {appSyncConfig} from "./appsync.ts";

export const config = {
    Auth: Auth,
    API: API,
    aws_appsync_graphqlEndpoint: appSyncConfig.endpoint,
    aws_appsync_region: appSyncConfig.region,
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
};