import {
    getSubscription,
    listSubscriptions
} from './queries';
import {
    createSubscription,
    deleteSubscription,
    updateSubscription,
} from './mutations';

import {GraphQLQuery,} from '@aws-amplify/api';

import {API} from 'aws-amplify';
import {Subscription} from "../types.ts";


export type ListSubscriptions = {
    listSubscriptions: { items: Subscription[] }
}

export type GetSubscription = {
    getSubscription: Subscription
}

export type CreateSubscription = {
    createSubscription: Subscription
}

export type UpdateSubscription = {
    updateSubscription: Subscription
}

export type DeleteSubscription = {
    deleteSubscription: string
}


export const fetchRecords = async (filter: object) => {

    try {
        const response = await API.graphql<GraphQLQuery<ListSubscriptions>>({
                                                                                query: listSubscriptions,
                                                                                variables: {filter: filter},
                                                                            });
        console.debug('in the fetch', response)

        return response.data?.listSubscriptions.items;
    } catch (e) {
        console.trace(e);
    }
};

export const fetch = async (id: string) => {
    try {
        const response = await API.graphql<GraphQLQuery<GetSubscription>>({
                                                                              query: getSubscription,
                                                                              variables: {id: id},
                                                                          });
        return response.data?.getSubscription;
    } catch (e) {
        console.trace(e);
    }
};

export const add = async (payload: object) => {

    try {
        const response = await API.graphql<GraphQLQuery<CreateSubscription>>({
                                                                                 query: createSubscription,
                                                                                 variables: {input: payload},
                                                                             });
        console.debug('in the service response', response);

        return response.data?.createSubscription;
    } catch (e) {
        console.trace(e);
    }
};

export const update = async (id: string, record: Subscription) => {

    console.debug('IN UPDATE', id, record);

    delete record.created;
    delete record.updated;

    try {
        const response = await API.graphql<GraphQLQuery<UpdateSubscription>>({
                                                                                 query: (updateSubscription),
                                                                                 variables: {input: record},
                                                                             });

        return response.data?.updateSubscription;
    } catch (e) {
        console.trace(e);
    }
};

export const deleteRecord = async (id: string) => {
    try {
        const response = await API.graphql<GraphQLQuery<DeleteSubscription>>({
                                                                                 query: (deleteSubscription),
                                                                                 variables: {input: {id: id}},

                                                                             });
        return response.data?.deleteSubscription;
    } catch (e) {
        console.trace(e);
    }
};
